<template>
  <v-card
    variant="tonal"
    :color="safeColor"
    class="text-center ma-2"
    density="compact"
    v-bind="$attrs"
  >
    <v-toolbar
      :color="safeColor"
      class="text-left"
      density="compact"
      :title="safeHeader"
    >
      <!-- <v-avatar
        variant="tonal"
        :icon="safeIcon"
      />
      <v-toolbar-title>{{ safeHeader }}</v-toolbar-title> -->
    </v-toolbar>

    <v-card-text>
      <v-avatar
        :size="72"
        variant="tonal"
        :color="safeColor"
      >
        <v-icon
          :size="48"
          :icon="safeIcon"
        />
      </v-avatar>

      <h3 class="text-h3 ma-4">
        {{ safeTitle }}
      </h3>

      <h4 class="text-h5 ma-4">
        {{ safedescription }}
      </h4>

      <slot>
        <p>{{ safenote }}</p>
      </slot>
    </v-card-text>

    <v-card-actions class="justify-center mb-4">
      <mtf-auth-trigger
        class="ml-2"
        :color="safeColor"
        :icons="false"
        :x-small="mobile"
        :has-profile="false"
        variant="tonal"
      />

      <mtf-actions
        :actions="actions"
        class="mt-8 d-flex align-center justify-center"
        @action="$emit('action', $event)"
      />

      <!-- <v-btn text @click="dialog.value = false">Close</v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { useDisplay } from 'vuetify';
import MtfActions from '@matterific/components/Actions.vue';

export default defineComponent({
  name: 'MtfForbidden',
  components: {
    MtfActions
  },
  inheritAttrs: true,
  props: {
    header: String,
    icon: String,
    title: String,
    description: String,
    note: String,
    actions: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['action'],
  customOptions: {},
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },

  computed: {
    safeHeader() {
      return this.header || 'Not authorized';
    },
    safeIcon() {
      return this.icon || 'mdi-lock-alert';
    },
    safeTitle() {
      return this.title || 'Oops!';
    },
    safedescription() {
      return this.description || 'Sorry, you are currently unable to view this page';
    },
    safenote() {
      return this.note || 'If you believe this to be a mistake, please contact us';
    },
    safeColor() {
      return this.$attrs?.color || 'error';
    }
  }
});
</script>
